import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Link,
  Typography,
} from '@mui/material';
import {
  AccountBalance,
  Place,
  AccountCircle,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ExpandableTagList from 'generic/components/ui/ExpandableTagList';
import QES_CONSTANTS from 'generic/core/qes/constants';
import goToCVE from 'generic/utils/cveUtils';
import goToMitre from 'generic/utils/mitreUtils';
import { overrideRessource } from 'generic/utils/utils';
import { useColorSchemeDetector } from 'generic/core/hooks/useColorSchemeDetector';
import JsxJoinerWithSeparator from 'generic/components/ui/JsxJoinerWithSeparator';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);

const { CLICK_ON_DOCS_TITLE_BEHAVIOR } = QES_CONSTANTS;

const Document = ({
  disableTagsRefine,
  displayTitle,
  document,
  handleGetDocumentComplete,
  isCompleteDisplay,
}) => {
  const currentTheme = useColorSchemeDetector();
  const { relationsEntitiesColors } = currentTheme.HIGHCHARTS;

  const { t } = useTranslation();
  const {
    date, pdf, snippet, sentences, status, text, title, linkout, attachment,
  } = useDocumentFields(document);
  const { qesdocument } = document;

  let titleLinkProps;
  if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'newTab') {
    titleLinkProps = {
      component: Link,
      href: pdf || linkout,
      rel: 'noopener',
      target: '_blank',
      underline: 'none',
    };
  } else if (CLICK_ON_DOCS_TITLE_BEHAVIOR === 'complete') {
    titleLinkProps = {
      component: 'span',
      onClick: handleGetDocumentComplete,
    };
  }

  return (
    <Box display="block" width="100%" sx={{ wordBreak: 'break-word' }}>
      {displayTitle && (
        <Typography
          variant="h6"
          component="span"
          fontSize="1.2rem"
          margin="6px 0"
          display="block"
        >
          <Box
            {...titleLinkProps}
            color="text.primary"
            sx={{ cursor: 'pointer' }}
          >
            {title}
          </Box>
        </Typography>
      )}
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow="1">
          <Box lineHeight="1.3rem">
            <Box
              display="flex"
              flexWrap="wrap"
              mb={0.5}
            >
              <JsxJoinerWithSeparator items={[
                date && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {date}
                  </Typography>
                ),
                status > 0 && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {t(`ged.document.status.${status}`)}
                  </Typography>
                ),
                (pdf || linkout) && (
                  <Link
                    href={pdf || linkout}
                    rel="noopener"
                    target="_blank"
                    underline="none"
                  >
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      PDF
                    </Typography>
                  </Link>
                ),
                attachment && (
                  <Link
                    href={attachment}
                    rel="noopener"
                    target="_blank"
                    underline="none"
                  >
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      JSON
                    </Typography>
                  </Link>
                ),
              ]}
              />
            </Box>

            {sentences && (
              sentences.map((sentence) => (
                <Box p={1} mt={0.5} mb={1} borderLeft="5px solid #d3d3d3" whiteSpace="pre-wrap" lineHeight="22px">
                  {sentence}
                </Box>
              ))
            )}
            {!isCompleteDisplay && (
              <div>
                {snippet}
              </div>
            )}
            <Box mt={0.5}>
              <ExpandableTagList
                disableRefine={disableTagsRefine}
                qesdocument={qesdocument}
                tags={[
                  { fieldName: 'QES_Company', icon: AccountBalance },
                  { fieldName: 'QES_Person', icon: AccountCircle },
                  { fieldName: 'QES_Product', icon: Place },
                  { fieldName: 'QES_Concept' },
                  { fieldName: 'QES_StixVulnerability', externalLink: goToCVE },
                  { fieldName: 'QES_MitreTactics', externalLink: goToMitre },
                  { fieldName: 'QES_MitreTechniques', externalLink: goToMitre },
                  { fieldName: 'QES_MitreMitigations', externalLink: goToMitre },
                  { fieldName: 'QES_MitreSoftwares', externalLink: goToMitre },
                  { fieldName: 'QES_MitreCampaigns', externalLink: goToMitre },
                  { fieldName: 'QES_Ipv4' },
                  { fieldName: 'QES_Ipv6' },
                ].map((field, index) => ({
                  ...field,
                  color: relationsEntitiesColors[index],
                }))}
              />
            </Box>
            {isCompleteDisplay && (
              <Box mt={1} mb={2} whiteSpace="pre-wrap" sx={{ clear: 'both' }}>
                {text}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Document.propTypes = {
  disableTagsRefine: PropTypes.bool,
  displayTitle: PropTypes.bool,
  // description du document QES attendu pour ce client
  document: PropTypes.shape({
    etat: PropTypes.number,
    base: PropTypes.number,
    qesdocument: PropTypes.shape({
      file_creation_date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }),
    files_pdf: PropTypes.string,
    files_text: PropTypes.string,
    title: PropTypes.string,
  }),
  handleGetDocumentComplete: PropTypes.func,
  isCompleteDisplay: PropTypes.bool,
};

Document.defaultProps = {
  disableTagsRefine: false,
  displayTitle: true,
  document: {},
  handleGetDocumentComplete: null,
  isCompleteDisplay: false,
};

export default Document;
