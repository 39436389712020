import React, { Fragment } from 'react';

import {
  Box,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { qesdocumentPropType } from 'generic/core/qes/proptypes';
import { overrideRessource } from 'generic/utils/utils';

const { default: useDocumentFields } = overrideRessource('core/hooks/useDocumentFields', true);

const DocumentMoreLikeThis = ({
  qesdocument,
}) => {
  const { t } = useTranslation();
  const { title, linkout, date } = useDocumentFields({
    base: +qesdocument.BASE_ID,
    qesdocument: { ...qesdocument },
  });

  return (
    <Box display="block">
      <Link
        underline="none"
        href={linkout}
        target="_blank"
        rel="noopener"
        color="text.primary"
        fontWeight="500"
        fontSize="0.8rem"
        display="block"
      >
        {title || t('document.title_undefined')}
      </Link>
      <Box
        display="flex"
        flexWrap="wrap"
        flexGrow="1"
        lineHeight="1rem"
        sx={{ wordBreak: 'break-word' }}
      >
        {date && (
          <Fragment>
            <Typography
              component="span"
              variant="body2"
              color="text.neutral"
              fontSize="0.8rem"
            >
              {date}
            </Typography>
            <Box mx={1} py="3px">
              <Divider
                orientation="vertical"
              />
            </Box>
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

DocumentMoreLikeThis.propTypes = {
  qesdocument: qesdocumentPropType,
};

DocumentMoreLikeThis.defaultProps = {
  qesdocument: {},
};

export default DocumentMoreLikeThis;
