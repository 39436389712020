import PropTypes from 'prop-types';
import { stringOrArrayOfStrings, validatesNumericOrNumericArrayType } from 'generic/utils/propTypeUtils';

const qesdocumentPropType = PropTypes.shape({
  title: stringOrArrayOfStrings.isRequired,
  BASE_ID: stringOrArrayOfStrings.isRequired,
  file_creation_date: stringOrArrayOfStrings.isRequired,
  SOURCE: stringOrArrayOfStrings,
  URL: stringOrArrayOfStrings,
  QUOTE_URL: stringOrArrayOfStrings,
  ETAT: validatesNumericOrNumericArrayType,
});

const documentPropType = PropTypes.shape({
  qesdocument: qesdocumentPropType,
});

export {
  documentPropType,
  qesdocumentPropType,
};
