import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import {
  AppBar,
  Box,
  ClickAwayListener,
  Divider,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { Menu } from '@mui/icons-material';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import ToggleMobileSearchIconContainer from 'generic/containers/ToggleMobileSearchIconContainer';
import { utilisateurPropType } from 'generic/core/qes/proptypes';
import AccountMenu from 'generic/components/ui/AccountMenu';
import DropdownMenu from 'generic/components/ui/DropdownMenu';
import { overrideRessource } from 'generic/utils/utils';
import HeaderSearchWrapper from 'generic/components/ui/HeaderSearchWrapper';
import { useDispatch, useSelector } from 'react-redux';
import HeaderMenuItem from 'generic/components/ui/HeaderMenuItem';
import { isHeaderBackgroundColorDark } from 'generic/utils/colorUtils';
import { toggleIsRAGInputOpened } from 'generic/core/ux/actions';
import { cleanupResults } from 'generic/core/search/actions';

const logoWhite = overrideRessource('img/header-logo.svg', true);
const logoBlack = overrideRessource('img/header-logo-black.svg', true);

const useStyles = makeStyles((theme) => ({
  logo: theme.custom.logo,
}));

const LinksMenu = ({ mobile, webTrackingAccess }) => {
  const defaultBaseId = useSelector((state) => state.config.settings.baseInterne_368);
  const baseId = useSelector((state) => state.config.activeBase.base);
  const isRAGSearch = useSelector((state) => state.search.isRAGSearch);
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const Item = mobile ? MenuItem : HeaderMenuItem;
  return (
    <Fragment>
      {webTrackingAccess && (
        <Item
          component={NavLink}
          to="/anr/folders"
          selected={location.pathname.startsWith('/anr/folders')}
        >
          {t('header.web_tracking')}
        </Item>
      )}
      <Item
        component={NavLink}
        to={`/search/dashboard/${defaultBaseId}`}
        onClick={() => {
          dispatch(toggleIsRAGInputOpened(false));
          if (isRAGSearch) {
            dispatch(cleanupResults());
          }
        }}
        selected={location.pathname === `/search/dashboard/${defaultBaseId}`}
      >
        {t('header.explore')}
      </Item>
      <Item
        component={NavLink}
        onClick={() => dispatch(toggleIsRAGInputOpened(false))}
        to="/search/dashboard/22801208"
        selected={location.pathname === '/search/dashboard/22801208'}
      >
        {t('header.relations')}
      </Item>
      <Item
        component={NavLink}
        to={{ pathname: 'https://enrichdev.qwamci.com/' }}
        target="_blank"
      >
        Enrich
      </Item>
      {mobile && (
        <Fragment>
          <Divider />
          <Item
            component={NavLink}
            to={`/search/dashboard/${baseId}${location?.search || ''}`}
            selected={location.pathname.startsWith('/search/dashboard')}
          >
            {t('header.dashboard')}
          </Item>
          <Item
            component={NavLink}
            to={`/search/results/${baseId}${location?.search || ''}`}
            selected={location.pathname.startsWith('/search/results')}
          >
            {t('header.results')}
          </Item>
        </Fragment>
      )}
    </Fragment>
  );
};

LinksMenu.propTypes = {
  webTrackingAccess: PropTypes.bool,
  mobile: PropTypes.bool,
};

LinksMenu.defaultProps = {
  webTrackingAccess: false,
  mobile: false,
};

const Header = ({
  user,
  hasActiveCollection,
  hasCarts,
  hasCollections,
  hasWatches,
}) => {
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    setIsSearchInputVisible(!smallerThanLarge);
  }, [smallerThanLarge]);

  const forceHideMenusForMobile = smallerThanLarge && isSearchInputVisible;
  const forceShowMenusForMobile = smallerThanLarge && !isSearchInputVisible;
  const toolbarPadding = smallerThanLarge ? '10px' : '30px';
  const activeLogo = isHeaderBackgroundColorDark(theme) ? logoWhite : logoBlack;

  return (
    <ClickAwayListener
      onClickAway={() => (forceHideMenusForMobile ? setIsSearchInputVisible(false) : _.noop)}
      mouseEvent="onMouseDown"
    >
      <Box width="100%">
        <AppBar elevation={0} position="static" color="header" enableColorOnDark>
          <Toolbar
            className={clsx('displayFlex', 'justifyContentSpaceBetween')}
            sx={{ pl: toolbarPadding, pr: toolbarPadding }}
          >
            <Fragment>
              <Box
                display="flex"
                alignItems="center"
                gap={1}
              >
                <img
                  src={activeLogo}
                  className={classes.logo}
                  alt="Qwam Logo"
                  style={{ display: forceHideMenusForMobile ? 'none' : 'block' }}
                />
                { hasActiveCollection && (
                  <Fragment>

                    <DropdownMenu
                      btnChildren={<Menu />}
                      btnProps={{
                        sx: {
                          display: forceShowMenusForMobile ? 'flex' : 'none',
                          padding: '8px',
                          backgroundColor: isHeaderBackgroundColorDark(theme) ? 'unset' : 'rgba(0, 0, 0, 0.1)',
                          color: isHeaderBackgroundColorDark(theme) ? '#ffffff' : '#000000',
                          '&:hover': {
                            backgroundColor: isHeaderBackgroundColorDark(theme)
                              ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
                          },
                        },
                      }}
                    >
                      <LinksMenu
                        mobile
                        webTrackingAccess={user.accesAnR}
                        hasCarts={hasCarts}
                        hasWatches={hasWatches}
                      />
                    </DropdownMenu>
                    {!smallerThanLarge && (
                      <LinksMenu
                        webTrackingAccess={user.accesAnR}
                        hasCarts={hasCarts}
                        hasWatches={hasWatches}
                      />
                    )}
                  </Fragment>
                )}
              </Box>
              {hasActiveCollection && (
                <HeaderSearchWrapper
                  showSearchInput={isSearchInputVisible}
                  headerBackgroundColorIsDark={isHeaderBackgroundColorDark(theme)}
                />
              )}
              <Box display="flex" alignItems="center">
                {hasActiveCollection && (
                  <ToggleMobileSearchIconContainer
                    handleClick={() => setIsSearchInputVisible(true)}
                    isVisible={forceShowMenusForMobile}
                    headerBackgroundColorIsDark={isHeaderBackgroundColorDark(theme)}
                  />
                )}
                <Box
                  sx={{
                    display: forceHideMenusForMobile ? 'none' : 'inline-block',
                  }}
                >
                  { user && (
                    <AccountMenu
                      user={user}
                      hasCollections={hasCollections}
                      hasActiveCollection={hasActiveCollection}
                      hasCarts={hasCarts}
                      hasWatches={hasWatches}
                      webTrackingAccess={user.accesAnR}
                      avatarSx={
                        { boxShadow: `0 0 4px 0px ${isHeaderBackgroundColorDark(theme) ? '#ffffff' : '#c5c5c5'}` }
                      }
                    />
                  )}
                </Box>
              </Box>
            </Fragment>
          </Toolbar>
        </AppBar>
      </Box>
    </ClickAwayListener>
  );
};

Header.propTypes = {
  user: utilisateurPropType,
  hasActiveCollection: PropTypes.bool,
  hasCarts: PropTypes.bool,
  hasCollections: PropTypes.bool,
  hasWatches: PropTypes.bool,
};

Header.defaultProps = {
  user: undefined,
  hasActiveCollection: false,
  hasCarts: false,
  hasCollections: false,
  hasWatches: false,
};

export default Header;
