export default {
  DEFAULT_SEARCH_PER_BASE: {
    22801208: {
      DATE_10000029_comp: 1,
      DATE_10000029_from: '01/01/2100',
    },
  },
  // Affichage du complet au centre des résultats pleine page
  COMPLETE_ON_RESULTS_FULL_PAGE: false,

  DEFAULT_WIDGETS_FORMS_PARAMS: {
    datatable: {
      computeLink: '',
    },
  },

  override_all: {
    DASHBOARDS_PARAMS_PER_BASE: {
      default: {
        COOCCURRENCES_FIELDS: [
          { value: 'QES_StixIdentity.verbatim', name: 'Identity', active: true },
          { value: 'QES_StixThreatActor.verbatim', name: 'Threat Actor', active: true },
          { value: 'QES_StixAttackPattern.verbatim', name: 'Attack Pattern', active: true },
          { value: 'QES_StixInfrastructure.verbatim', name: 'Infrastructure' },
          { value: 'QES_StixMalware.verbatim', name: 'Malware' },
          { value: 'QES_StixTool.verbatim', name: 'Tool' },
          { value: 'QES_StixObservedData.verbatim', name: 'Observed Data' },
          { value: 'QES_Ipv4.verbatim', name: 'IPv4' },
          { value: 'QES_Ipv6.verbatim', name: 'IPv6' },
          { value: 'QES_Md5.verbatim', name: 'MD5' },
        ],
      },
      22801208: {
        RELATIONS_NODES_FIELDS: [
          { value: 'Organonoff', name: 'Organonoff', active: true },
          { value: 'Organization', name: 'Organization', active: true },
          { value: 'Product', name: 'Product', active: true },
          { value: 'Person', name: 'Person', active: true },
          { value: 'Concept', name: 'Concept', active: true },
          { value: 'Coldconcept', name: 'Coldconcept', active: true },
          { value: 'Location', name: 'Location', active: true },
          { value: 'Company', name: 'Company', active: true },
          { value: 'Event', name: 'Event', active: true },
          { value: 'Uppers', name: 'Uppers', active: true },
          { value: 'Media', name: 'Media', active: true },
        ],

        RELATIONS_LINKS_FIELDS: [
          { name: 'Find', value: 'Find', active: true },
          { name: 'Use', value: 'Use' },
          { name: 'Target', value: 'Target' },
          { name: 'Report', value: 'Report' },
          { name: 'Attack', value: 'Attack' },
          { name: 'Aim', value: 'Aim' },
          { name: 'Launch', value: 'Launch' },
          { name: 'Identify', value: 'Identify' },
          { name: 'Investigate', value: 'Investigate' },
          { name: 'Supply', value: 'Supply' },
          { name: 'Detect', value: 'Detect' },
          { name: 'Deploy', value: 'Deploy' },
          { name: 'Run', value: 'Run' },
          { name: 'Create', value: 'Create' },
          { name: 'Support', value: 'Support' },
          { name: 'Develop', value: 'Develop' },
          { name: 'Exploit', value: 'Exploit' },
          { name: 'Help', value: 'Help' },
          { name: 'Include', value: 'Include' },
          { name: 'Appoint', value: 'Appoint' },
          { name: 'Hit', value: 'Hit' },
          { name: 'Expose', value: 'Expose' },
          { name: 'Kill', value: 'Kill' },
          { name: 'Steal', value: 'Steal' },
          { name: 'Observe', value: 'Observe' },
          { name: 'Track', value: 'Track' },
          { name: 'Attribute', value: 'Attribute' },
          { name: 'Tell', value: 'Tell' },
          { name: 'Buy', value: 'Buy' },
          { name: 'Provide', value: 'Provide' },
          { name: 'Lead', value: 'Lead' },
          { name: 'Compromise', value: 'Compromise' },
          { name: 'Conduct', value: 'Conduct' },
          { name: 'Utilize', value: 'Utilize' },
          { name: 'Suffer', value: 'Suffer' },
          { name: 'Take', value: 'Take' },
          { name: 'Conflict', value: 'Conflict' },
          { name: 'Test', value: 'Test' },
          { name: 'Dismiss', value: 'Dismiss' },
          { name: 'Uncover', value: 'Uncover' },
          { name: 'Impact', value: 'Impact' },
          { name: 'Involve', value: 'Involve' },
          { name: 'Affect', value: 'Affect' },
          { name: 'Deliver', value: 'Deliver' },
          { name: 'Issue', value: 'Issue' },
          { name: 'Prevent', value: 'Prevent' },
          { name: 'Introduce', value: 'Introduce' },
          { name: 'Manage', value: 'Manage' },
          { name: 'Declare', value: 'Declare' },
          { name: 'Alert', value: 'Alert' },
          { name: 'Evolve', value: 'Evolve' },
          { name: 'Host', value: 'Host' },
          { name: 'Leverage', value: 'Leverage' },
          { name: 'Say', value: 'Say' },
          { name: 'See', value: 'See' },
          { name: 'Confirm', value: 'Confirm' },
          { name: 'Contain', value: 'Contain' },
          { name: 'Infect', value: 'Infect' },
          { name: 'Receive', value: 'Receive' },
          { name: 'Describe', value: 'Describe' },
          { name: 'Encourage', value: 'Encourage' },
          { name: 'Document', value: 'Document' },
          { name: 'Capture', value: 'Capture' },
          { name: 'Offer', value: 'Offer' },
          { name: 'Release', value: 'Release' },
          { name: 'Arrest', value: 'Arrest' },
          { name: 'Give', value: 'Give' },
          { name: 'Send', value: 'Send' },
          { name: 'Assess', value: 'Assess' },
          { name: 'Discover', value: 'Discover' },
          { name: 'Link', value: 'Link' },
          { name: 'Show', value: 'Show' },
          { name: 'Allow', value: 'Allow' },
          { name: 'Distribute', value: 'Distribute' },
          { name: 'Monitor', value: 'Monitor' },
          { name: 'Employ', value: 'Employ' },
          { name: 'Participate', value: 'Participate' },
          { name: 'Accuse', value: 'Accuse' },
          { name: 'Disrupt', value: 'Disrupt' },
          { name: 'Hack', value: 'Hack' },
          { name: 'Publish', value: 'Publish' },
          { name: 'Recommend', value: 'Recommend' },
          { name: 'Get', value: 'Get' },
          { name: 'Notify', value: 'Notify' },
          { name: 'Reveal', value: 'Reveal' },
          { name: 'Mention', value: 'Mention' },
          { name: 'Accused', value: 'Accused' },
          { name: 'Claim', value: 'Claim' },
          { name: 'Impersonate', value: 'Impersonate' },
          { name: 'Sell', value: 'Sell' },
          { name: 'Contact', value: 'Contact' },
          { name: 'Disable', value: 'Disable' },
          { name: 'Open', value: 'Open' },
          { name: 'Prompt', value: 'Prompt' },
          { name: 'Spoof', value: 'Spoof' },
          { name: 'Charge', value: 'Charge' },
          { name: 'Join', value: 'Join' },
          { name: 'Start', value: 'Start' },
          { name: 'Block', value: 'Block' },
        ],
      },
    },
  },
  DYNAMIC_DASHBOARD: false,
};
